import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../../../../components/Button';

import { getRoute, paths } from '../../../../entry/routes';
import { toggleAuthPopup } from '../../../../state/modules/ui/actions';

const LoginButton = ({ className, children, onClick, ...props }) => {
  const isRealPhone = useSelector(state => state.responsive.isRealPhone);
  const history = useHistory();
  const dispatch = useDispatch();

  const clickHandler = useCallback(() => {
    if (isRealPhone) {
      history.push(getRoute(paths.signin));
    } else {
      dispatch(toggleAuthPopup(true));
    }
    if (onClick) {
      onClick();
    }
    onClick();
  }, [isRealPhone,dispatch, history]);


  return (<Button
      className={className}
      onClick={clickHandler}
      {...props}
    >
      {children}
    </Button>);
};

export default LoginButton;
