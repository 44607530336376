import React from 'react';
import classNames from 'classnames/bind';
import { useSelector } from 'react-redux';
import { isValentine25Theme, specialTheme } from '../../../state/modules/city/selectors';

import { Valentine25Link } from '../../Valentine25';
import styles from './PromoLinks.styl';
import { themeNameSpaces } from '../../../state/modules/city/themeNameSpaces';
import { Fools25PageLink } from '../../PromoFoolsDay25/components/Fools25PageLink/Fools25PageLink';

const cx = classNames.bind(styles);

const PromoLinks = () => {
  const theme = useSelector(specialTheme);

  const isValentine25 = useSelector(isValentine25Theme);

  return (
    <div className={cx(styles.PromoLinks)}>
      {isValentine25 && <Valentine25Link />}
      {theme === themeNameSpaces.FOOLS_DAY_25 && <Fools25PageLink />}
    </div>
  );
};

export default PromoLinks;
