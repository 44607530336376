import React from 'react';
import { useIntl } from 'react-intl';

import ModalGeneral from '../../../../components/ModalGeneral/ModalGeneral';
import LoginButton from './LoginButton';

import { useFestiveEffect } from '../useFestiveEffect';

//Styles
import styles from './LoginModal.styl';

const LoginModalKy = ({ isOpen, onClose }) => {
  const intl = useIntl();



  return <ModalGeneral isOpen={isOpen} onClose={onClose} className={styles.LoginModalKy}>
    <p className={styles.LoginModalKy__text}>{intl.formatMessage({ id: 'promoGame.ky.login.title' })}</p>
    <p className={styles.LoginModalKy__subtext}>{intl.formatMessage({ id: 'promoGame.ky.login.subtitle' })}</p>
    <LoginButton className={styles.LoginModalKy__Login} onClick={onClose}>{intl.formatMessage({ id: 'promoGame.ky.login.button' })}</LoginButton>
  </ModalGeneral>;
};

export default LoginModalKy;
