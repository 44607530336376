import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { themeNameSpaces } from '../../../../state/modules/city/themeNameSpaces';
import LoginModalKy from './LoginModalKy';
import { closeGamePopupLogin } from '../../../../state/modules/promotionGame/actions';

const LoginModalPromoGame = () => {
  const promoGameProperties = useSelector(state => state.promotionGame);
  const dispatch = useDispatch();


  const {
    gameName,
    common: {
      isLoginPopupOpen,
    },
  } = promoGameProperties;


  const ModalComp = useMemo(() => {
    if (gameName === themeNameSpaces.KY) {
      return LoginModalKy;
    }

  }, [gameName]);

  const closeHandler = useCallback(() => {
    dispatch(closeGamePopupLogin())
  },[dispatch])


  return ModalComp ? <ModalComp isOpen={isLoginPopupOpen} onClose={closeHandler}  /> : null;

};

export default LoginModalPromoGame;
